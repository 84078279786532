import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImg from 'gatsby-image'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'

import scopeLogo from '../images/scope-logo.svg'
import ctrRunLogo from '../images/ctr-run-logo.svg'

const imagesQuery = graphql`
  query ImagesQuery {
    scopeIllustration: file(relativePath: { eq: "scope-illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 465) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ctrRunIllustration: file(relativePath: { eq: "ctr-run-illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 465) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
const Img = ({ id, alt = '' }) => {
  const imagesData = useStaticQuery(imagesQuery)
  const { fluid, fixed } = imagesData[id].childImageSharp
  return <GatsbyImg className="img" alt={alt} fluid={fluid} fixed={fixed} />
}

const Product = ({
  name,
  children: description,
  logo,
  illustrationId,
  link,
  main = false,
}) => (
  <section id="product">
    <div>
      <img src={logo} alt={name} />
      <p>{description}</p>
      <a
        className={`button ${main ? 'primary' : ''}`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </div>
    <Img id={illustrationId} />
  </section>
)

const IndexPage = () => (
  <Layout>
    <Helmet>
      <body className="home" />
    </Helmet>
    <SEO
      title="Undefined Labs"
      keywords={['developer tools', 'developers', 'software']}
      description="We build tools to solve challenges in software engineering so development teams can do more, faster."
    />

    <section id="intro">
      <h2>
        We build tools to solve challenges in software engineering so
        development teams can do more, faster.
      </h2>
    </section>

    <Product
      name="Scope"
      logo={scopeLogo}
      illustrationId="scopeIllustration"
      link="https://scope.dev"
      main
    >
      Scope turns tests into the insights you need to ship higher quality
      software more frequently.
    </Product>

    <Product
      name="ctr.run"
      logo={ctrRunLogo}
      illustrationId="ctrRunIllustration"
      link="https://ctr.run"
    >
      Modern, fast, efficient & on-demand Docker builds for all your application
      needs.
    </Product>
  </Layout>
)

export default IndexPage
